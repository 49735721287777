import React from 'react'
import PropTypes from 'prop-types';
import { GatsbyImage } from "gatsby-plugin-image"


/**
 *	Returns a image component using GraphQL query and gatsby image plugin for fast load.
 *	@component
 */
const FluidImage = ({image, altName, wrapperCname, wrapperStyle, fit}) => {
	return <GatsbyImage image={image} className={wrapperCname} alt={altName} objectFit={fit}/>;
}


export default FluidImage


FluidImage.propTypes = {
	/**
	 *	Alt name for the image component.
	 */
	altName: PropTypes.string,
	/**
	 *	CSS style name to apply to the className of the gatsby image component.
	 */
	wrapperCname: PropTypes.string,
	/**
	 *	CSS style to apply to the wrapper element of the gatsby image plugin.
	 */
	wrapperStyle: PropTypes.objectOf(PropTypes.string),
	/**
	 *	CSS style to apply to the img element of the gatsby image plugin.
	 */
	imgStyle: PropTypes.objectOf(PropTypes.string),
}
