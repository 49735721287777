import React from "react"
import { Link, graphql } from "gatsby"
import Seo from "../components/seo"
import Layout from "../components/layout"
import * as styles from "./work_page.module.css"
import FluidImage from "../components/fluid_image"
import { getImage } from "gatsby-plugin-image"
import ColoredSeparator from "../components/colored_sep"


const WorkPageTemplate = ({data}) => {
	const work = data.mdx
	const cellCSS = work.frontmatter.grid_type === "half"
	 				? [styles.cellitem, styles.half].join(" ")
					: [styles.cellitem, styles.quorter].join(" ");


	return (
		<Layout>
			<Seo
				title="Work"
				subTitle={work.frontmatter.title}
				description={work.frontmatter.description || work.excerpt}
			/>
			<div className={styles.grid}>
				<div className={styles.cellitem}>
					<h1 className={styles.workTitle}>{work.frontmatter.title}</h1>
					<h2 className={styles.category}>{work.frontmatter.category}</h2>
				</div>

				<div className={styles.cellitem}>
					<p>{work.frontmatter.description}</p>
				</div>

				{ work.frontmatter.workList !== "undefined" &&
					work.frontmatter.workList.map( ({ workItem }) =>
					<div key={workItem.id} className={cellCSS}>
						{
							(workItem.ext === ".gif")
							?	<img src={workItem.publicURL}
									 alt={workItem.name}
								 />
							: (workItem.ext === ".mp4")
							?	<video width="100%" height="500px" object-fit="cover" controls>
								   <source src={workItem.publicURL} type="video/mp4"></source>
								   Your Browser does not support video player feature.
								</video>
							:	<>
								<FluidImage
									image={getImage(workItem)}
									wrapperCname={styles.img_wrapper}
									altName={workItem.name}
									fit="contain"
								/>
								</>
						}
					</div>
				)
				}

				<div className={styles.cellitem + " " + styles.backLink}>
					<Link to="/" style={{textDecoration:"none", color:"black"}}>BACK TO WORKS</Link>
					<ColoredSeparator color="black" />
				</div>
			</div>
		</Layout>
	)
}

export default WorkPageTemplate


export const pageQuery = graphql`
query WorkBySlug($slug: String!) {
	mdx(fields: { slug: { eq: $slug } }) {
		frontmatter {
			title
			description
			category
			grid_type
			workList {
		        workItem {
					id
					name
					publicURL
					ext
					childImageSharp {
						gatsbyImageData (
							quality: 80
						)
					}
		        }
	      	}
		}
	}
}
`
